import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageSettingsService {
  private pageSettingsSubject = new BehaviorSubject<any>(null);
  pageSettings$ = this.pageSettingsSubject.asObservable();

  /**
   * Updates the page settings with the provided new settings.
   *
   * @param {any} newSettings - The new settings to update the page with.
   */
  updatePageSettings(newSettings: any) {
    this.pageSettingsSubject.next(newSettings);
  }
}
